import {heroMainText, heroSubText} from '../const/staticVariables';

export default function HeroText() {
    return (
        <div className="text-white poppins text-l">
            {/*{heroMainText[0]}*/}
            {/*<div>*/}
            {/*  {heroMainText[1]}*/}
            {/*  <span className="text-text-blue">{heroMainText[2]}</span>*/}
            {/*</div>*/}
            {/*/!* hero sub text *!/*/}
            {/*<div className="poppins text-xl font-normal mt-5">{heroSubText}</div>*/}
            <h4 className="bebas text-5xl text-[#0098d6]">PERIPOL CULTURE</h4>
            <p>
                Peripol Creative's culture is one we experience, create,
                <br/>
                and grow each and every day. We have enthusiasm,
                <br/>
                the source of all that we do, and curiosity, feeding our enthusiasm.
                <br/>
                There is the Peripol culture that makes us love what we do,
                <br/>
                and makes you love Peripol.
                <br/>
                <br/>
            </p>
            <h4 className="bebas text-5xl text-[#e99432]">There is empathy in this culture.</h4>
            <p>
                Being understanding is the basis of our culture.
                <br/>
                We love beauty of the works that stem from mutual empathy.
                <br/>
                There is no restriction and impossibility in our vocabulary.<br/>
                <br/>

            </p>
            <h4 className="bebas text-5xl text-[#57207c]">There is trust in this culture.</h4>
            <p>
                Our priorities are time and quality.
                <br/>
                We set our sights on this target,
                <br/>
                and Peripol never fails to deliver when locked on the target.
                <br/>
                <br/>
            </p>
            <h4 className="bebas text-5xl text-[#cf0033]">There is loyalty in this culture.</h4>
            <p>
                Ever since the dawn of time, there is one subject matter everyone and&nbsp;
                <br />
                    everything&nbsp;has always been on pursuit of: communication.
                    <br />
                        This subject won't go away anytime soon probably ever.
                        <br />
                            We are in this with you for the long haul.
                        <br />
                        <br />
            </p>

            <h4 className="bebas text-5xl text-[#0098d6]">There is innovation in this culture.</h4>
            <p>
                Innovation is idea blended with experience.
                <br />
                    We have enthusiasm, desire, excitement and<br />
                    never-ending curiosity to produce these ideas.
            </p>
        </div>
);
}
