import Countdown from './components/count-down';
import ReactGA from 'react-ga4';

import Form from './components/form';
import BrandsTable from './components/brands-table';

import Footer from './layout/footer';
import HeroVideo from './components/hero-video';
import HeroText from './components/hero-text';
import Tags from "./layout/MetaTags";
import {useEffect} from "react";

export default function App() {
    useEffect(() => {
        ReactGA.initialize('G-41Q9K6YP89');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);

    const experiences = [
        {title: "Marketing Manager, Male Grooming", brandLogo: "https://api.works.peripol.com/media/brandLogos/philips.png", fullname: "Ceyla Tiryakioğlu", experience: "Çözüm ortağı haline gelen Peripol Creative ile alışverişçinin mağaza içi karar yolculuğunu birlikte analiz ettik. Yaratıcı bir perspektif ile pazarda bugüne dek yapılmayanı önermeleri sayesinde, alışverişçiye aradığı ürünü en hızlı şekilde bulduran ve arkasında sağlıklı bir algoritması bulunan interaktif bir ekran yerleştirdik.  Uzaktan kontrol ile alışverişçiye mesajlarımızı anlık değiştirebildik ve alışverişçiyi anında satın alıma yönlendirmeyi başardık. Ayrıca online platforma taşıyarak dijital dünyada da yerimizi aldık. Peripol'un farklı içgörüler üzerine çözüm oluşturarak işinize katkı sağlayacağına inanıyorum."},
        {title: "Marketing Manager at Philips", brandLogo: "https://api.works.peripol.com/media/brandLogos/philips.png", fullname: "Burcu Okudur", experience: "Peripol, beni en iyi anlayan, en iyi çözümleri oldukça kısa zamanda sunan, çalışmaktan çok keyif aldığım, brief verirken hayal ettiğimden çok daha iyi bir iş ile sonuçlanacağına emin olduğum iş ortağım. Ayrıca işin büyük küçük oluşuna bakmadan her zaman aynı özveri ve titizlikle çalışan bir ajans. Pazarlama dünyasında olduğum sürece çalışacağıma hiç şüphe yok."}
    ]

    return (
        <div>
            <Tags title="Peripol Creative" description="We put ourselves in your shoes."
                  image_url="https://peripol.com/images/pageImage.jpg"/>
            {/* hero section */}
            <div className="flex flex-col items-center py-20 relative">
                <img src="images/logo.png" alt="logo" className="h-20 w-72"/>
                <div className="w-full flex flex-col px-10 md:px-5 md:grid md:grid-cols-12 gap-10 maxw center mt-20">
                    {/* hero left side */}
                    <div className="col-span-7 flex flex-col">
                        <HeroText/>
                        {/*<Countdown />*/}
                    </div>
                    {/* hero right side */}
                    <div className="col-span-5 w-auto h-full">
                        <Form/>
                    </div>
                </div>
                {/* hero background */}
                <div className="w-full h-full object-cover absolute inset-0 -z-10">
                    <HeroVideo/>
                </div>
            </div>

            {/* brands */}
            <div className="bg-background-light">
                <div className="flex flex-col items-center justify-center maxw center px-10 pt-7 pb-10">
                    <div className="text-text-primary poppins mb-10 font-semibold">
                        Together in the Journey
                    </div>
                    <div className="hidden lg:flex flex-row flex-wrap justify-center items-center">
                        <BrandsTable colCount={5}/>
                    </div>
                    <div className="hidden md:flex lg:hidden flex-row flex-wrap justify-center items-center">
                        <BrandsTable colCount={3}/>
                    </div>
                    <div className="flex md:hidden flex-row flex-wrap justify-center items-center">
                        <BrandsTable colCount={2}/>
                    </div>
                </div>
            </div>

            <div className="bg-background-light">
                <div className="flex flex-col items-center justify-center maxw center px-10 pt-7 pb-10">
                    <div className="text-text-blue poppins mb-10 font-semibold">
                        Experience
                    </div>
                    <div className="grid grid-cols-1 grid-rows-[auto_1fr] gap-x-10 max-lg:gap-y-10 lg:grid-cols-2 lg:gap-y-5">
                        {experiences.map((experience, index) =>
                            <div key={index}>
                                <figure
                                    className="group row-span-2 grid max-lg:mx-auto max-lg:max-w-3xl max-lg:gap-y-5 lg:grid-rows-subgrid lg:border-gray-950/5 lg:first:border-r lg:last:border-l">
                                    <blockquote
                                        className="max-lg:line-y lg:group-first:line-y mx-auto flex items-center px-8 py-2 font-medium tracking-tight sm:px-16">
                                        <p className="relative before:pointer-events-none before:absolute before:top-4 before:-left-6 before:text-[6rem] before:text-gray-950/10 before:content-['“'] sm:before:-left-8 lg:before:text-[8rem]">
                                            {experience.experience}</p></blockquote>
                                    <figcaption
                                        className="max-lg:line-y lg:group-first:line-y grid grid-cols-[max-content_1fr] gap-6 px-8 py-2 sm:px-16">
                                        <img
                                            className="size-14 rounded-full object-contain outline -outline-offset-1 outline-gray-950/5"
                                            src={experience.brandLogo}
                                            alt=""/>
                                        <div className="text-sm/7"><p className="font-medium">{experience.fullname}</p><p
                                            className="text-gray-600">{experience.title}</p></div>
                                    </figcaption>
                                </figure>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
